export default {
  title: 'Vistoria',
  menu: [
    {
      id: 'vistoria',
      title: 'Vistorias',
      icon: 'money-check',
      style: 'regular',
      url: '/#/vistoria'
    },
    {
      id: 'checklists',
      title: 'Checklists',
      icon: 'list',
      style: 'regular',
      url: '/#/remocao/checklist-acessorios'
    },
    {
      id: 'vistoriadores',
      title: 'Vistoriadores',
      icon: 'user',
      style: 'regular',
      url: '/#/vistoriadores/lista'
    }
  ]
}
